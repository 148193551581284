<template>
    <div class="page1">
        <EButton @click="dialogShow" type="primary">添加</EButton>
        <ETable :tableTitle="tableTitle" :tableData="tableData" :needPagination="false">
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="180px"
            >
                <template slot-scope="scope">
                    <div>
                        <EButton @click="editDialog(scope.row)" type="text">编辑</EButton>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                    fixed="right"
                    label="排序"
                    width="250px"
            >
                <template slot-scope="scope">
                    <div>
                        <EButton @click="getSort(scope.row.button_id,1)" type="text" :disabled="scope.row.sort==1">上移</EButton>
                        <EButton @click="getSort(scope.row.button_id,2)" type="text" :disabled="scope.row.sort==tableData.length">下移</EButton>
                        <!--                        <el-button type="text " @click="getSort(scope.row.button_id,1)" :disabled="scope.row.sort==1">上移-->
                        <!--                        </el-button>-->
                        <!--                        <el-button type="text " @click="getSort(scope.row.button_id,2)" :disabled="scope.row.sort==tableData.length">下移-->
                        <!--                        </el-button>-->
                    </div>
                </template>
            </el-table-column>
        </ETable>
        <EDialog :dialogVisible="dialogVisible" :title="title" @handleClose="cancelDialog"
        >
            <EForm :formColumns="formColumns"
                   :labelPosition="labelPosition"
                   :rowSize="1"
                   :optionsBtn="false"
                   :labelWidth="labelWidth"
                   :actionBtn="true"
                   :formData="form"
                   :formRules="formRules"
                   ref="form"
                   @handleClick="saveData"
                   @handleClose="cancelDialog"
                   :searchFlag="false"
            ></EForm>
<!--            <el-form label-position="right" label-width="100px">-->
<!--                <el-form-item label="按钮名称">-->
<!--                    <el-input placeHolder="最多可录入20字" maxlength="20" v-model="form.button_name	"></el-input>-->
<!--                </el-form-item>-->
<!--                <el-form-item>-->
<!--                    <el-button @click="cancelDialog">取消</el-button>-->
<!--                    <el-button type="primary" @click="addBtn">确定</el-button>-->
<!--                </el-form-item>-->
<!--            </el-form>-->

        </EDialog>
    </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import vxRule from "@/assets/js/formValidate";
  export default {
    name: 'Login',
    data() {
      return {
        tableTitle: [
          {
            label: '按钮名称',
            prop: 'button_name',
          },
        ],
        tableData: [],
        loading: false,
        dialogVisible: false,
        title: '添加按钮',
        form: {
          button_name:'',
          button_id:''
        },
        images:[],
        content:'',
        formColumns:[{
          title:'按钮名称',
          type:'text',
          property:'button_name',
          placeHolder:'最多可录入20字',
          require:true,
          show:true
        },
          {
            type:'actionBtn',
            show:true
          }
        ],
        formRules:{
          button_name: vxRule(true, '',"blur", "按钮名称不能为空"),
        },
        labelPosition:'right',
        labelWidth:'100px'
      }
    },
    watch: {},
    created() {
      this.buttonListsFn()
    },
    components: {ETable, EDialog,EButton,EForm},
    beforeMount() {

    },
    methods: {

      cancelDialog(){
        this.dialogVisible=false
        this.title='添加按钮'
        this.form={
          button_name:'',
          button_id:''
        }
      },
      dialogShow(){
        this.dialogVisible=true
      },
      async buttonListsFn() {
        let res = await Http.buttonLists()
        this.tableData = res.data.list
      },
      saveData(){
        console.log(this.$refs['form'])
        this.$refs['form'].$refs['form'].validate((valid, object) => {
          console.log(object)
          if (valid && !this.error) {
            this.addBtn()
          } else {
            return false;
          }
        });
      },
      async addBtn() {

        let res = await Http.addOrEditButton(this.form)
        if(res.flag==1){
          this.$message.success(res.msg)
          this.cancelDialog()
          this.buttonListsFn()
        }

      },
      editDialog(data){
        this.title='编辑按钮'
        this.form.button_name=data.button_name
        this.form.button_id=data.button_id
        console.log(this.form)
        this.dialogVisible=true
      },
      async getSort(id,sort_type){
        let res = await Http.setSortRule({id,sort_type,data_type:'menu_button'})
        this.$message.success(res.msg)
        this.buttonListsFn()
      }
    },
    getImgList(val){
      console.log(val)
      this.images = val
    },
    inputContent(value){
      this.content = value;
    },

  }
</script>


<style lang="scss" scoped>
    .login-container {
        min-height: 100%;
        width: 100%;
        background-color: #fff;
        overflow: hidden;

        .login-form {
            position: relative;
            width: 520px;
            max-width: 100%;
            padding: 160px 35px 0;
            margin: 0 auto;
            overflow: hidden;
            box-sizing: border-box;
        }

        .tips {
            font-size: 14px;
            color: #fff;
            margin-bottom: 10px;

            span {
                &:first-of-type {
                    margin-right: 16px;
                }
            }
        }

        .svg-container {
            padding: 6px 5px 6px 15px;
            color: #999;
            vertical-align: middle;
            width: 30px;
            display: inline-block;
        }

        .title-container {
            position: relative;

            .title {
                font-size: 26px;
                color: #fff;
                margin: 0px auto 40px auto;
                text-align: center;
                font-weight: bold;
            }
        }

        .show-pwd {
            position: absolute;
            right: 10px;
            top: 7px;
            font-size: 16px;
            color: #2d3a4b;
            cursor: pointer;
            user-select: none;
        }
    }
</style>

